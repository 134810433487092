/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextEditor from "../components/TextEditor";
import useCommonApi from "../hooks/common-api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import Loader from "../components/Loader";
import FaqComponent from "../components/FaqComponent";

function AiToolPage() {
  const [editorData, setEditorData] = useState("<p></p>");
  const [isSourceView, setIsSourceView] = useState(false);
  const contentEditableRef = useRef(null);
  const imageRef = useRef(null);
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [inputData, setinputData] = useState({
    // blog_title: "",
    // blog_meta_title: "",
    // blog_meta_description: "",
    // publish_date: "",
    title: "",
    description: "",
    slug: "",
    api: "",
    icon: "",
    meta_title: "",
    meta_description: "",
    tool_type:""
  });
  const [loading, setloading] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const { state } = useLocation();
  const { updateCreateFreeAiTool } = useCommonApi();
  const navigate = useNavigate();
  const params = useParams();
  const uploadedImages = useRef([]);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingTimeout = 1000; // Set the timeout value in milliseconds (adjust as needed)
  const [faqs, setFaqs] = useState([]);

  const addFaq = (index) => {
    const newFaq = { question: "", content: "" };
    const updatedFaqs = [
      ...faqs.slice(0, index + 1),
      newFaq,
      ...faqs.slice(index + 1),
    ];
    setFaqs(updatedFaqs);
  };

  const handleImageDelete = (oldData, newData) => {
    const oldImages = Array.from(oldData.matchAll(/<img.*?src="(.*?)"/g)).map(
      (match) => match[1]
    );
    const newImages = Array.from(newData.matchAll(/<img.*?src="(.*?)"/g)).map(
      (match) => match[1]
    );
    console.log(oldImages, newImages, "images");

    uploadedImages?.current?.forEach((oldImage) => {
      if (!newImages.includes(oldImage)) {
        deleteImage(oldImage, "editor");
      }
    });
  };
  const uploadIconFn = async (file) => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", file);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/editorimage`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        let blog_image = response.data.file_path;
        let admin_selected_blog = JSON.parse(
          localStorage.getItem("admin_selected_tool")
        );
        admin_selected_blog.blog_image = blog_image;
        localStorage.setItem(
          "admin_selected_tool",
          JSON.stringify(admin_selected_blog)
        );
        setinputData({
          ...inputData,
          blog_image: blog_image,
        });
        createBlogFn(null, contentEditableRef.current.innerHTML);
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };

  const createBlogFn = async (e, newData = "", from = "editor") => {
    if (e) {
      e.preventDefault();
    }
    // If rawfile
    // let blog_image =
    //   JSON.parse(localStorage.getItem("admin_selected_tool")).blog_image || "";

    // if (JSON.parse(localStorage.getItem("admin_selected_tool")).blog_image) {
    // blog_image = JSON.parse(
    //   localStorage.getItem("admin_selected_tool")
    // ).blog_image;
    // } else {
    //   if (rawFile) {
    //     let response = await uploadIconFn();
    //     blog_image = response.data;
    //   } else {
    //     blog_image = "";
    //   }
    // }
    // console.log(blog_image, "blog image");
    // let login data
    let tempElement = document.createElement("div");
    tempElement.innerHTML = contentEditableRef.current.innerHTML;

    // Get all elements with class 'hover-buttons' and remove them
    let hoverButtons = tempElement.querySelectorAll(".hover-buttons");
    hoverButtons.forEach((button) => button.parentNode.removeChild(button));

    // Get the cleaned HTML
    let cleanedHTML = tempElement.innerHTML;
    console.log(cleanedHTML, "cleaned");
    // let loginData = JSON.parse(localStorage.getItem("admin_user_data"));
    let data = {
      title: inputData?.title,
      description: inputData?.description,
      slug: inputData?.slug,
      api: inputData?.api,
      icon: inputData?.icon,
      meta_title: inputData?.meta_title,
      meta_description: inputData?.meta_description,
      content: cleanedHTML,
      faqs: faqs,
      tool_type: inputData?.tool_type,
    };
    if (params?.id == "new") {
      setloading(true);
      let res = await updateCreateFreeAiTool(data);
      setloading(false);
      if (!res.error) {
        setsuccessFailureMessage(res.message);
        setopenSuccessModal("dismissible");
        navigate(`/free_ai_tools/${res?.data?.id}`, { state });
      } else {
        setsuccessFailureMessage(res.message);
        setopenSuccessModal("dismissible");
      }

      localStorage.setItem(
        "admin_selected_tool",
        JSON.stringify({
          id: res?.data?.id,
          ...data,
          faqs: faqs,
        })
      );
    } else {
      let updatedData = {
        tool_id: params?.id,
        ...data,
      };
      setloading(true);
      let res = await updateCreateFreeAiTool(updatedData);
      setloading(false);
      if (!res.error) {
        console.log("came here...");
        setsuccessFailureMessage(res.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(res.message);
        setopenSuccessModal("dismissible");
      }
      localStorage.setItem(
        "admin_selected_tool",
        JSON.stringify({
          ...updatedData,
        })
      );
    }
  };
  const uploadImage = async (file) => {
    console.log(file, "gile");
    let formdata = new FormData();
    formdata.append("vsn_file", file);

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/editorimage`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        jwt_token: localStorage.getItem("admin_user_token"),
      },
      timeout: 10000,
      data: formdata,
    });

    if (!response.data.error) {
      return {
        default: response.data.file_path,
      };
    } else {
      throw new Error(response.data.message);
    }
  };

  const deleteImage = async (filePath, from = "editor") => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/delete/upload_file`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: {
          file_path: filePath,
        },
      });
      setrawFile(null);
      setpreviewUrl(null);
      imageRef.current.value = "";
      let admin_selected_blog = JSON.parse(
        localStorage.getItem("admin_selected_tool")
      );
      admin_selected_blog.blog_image = "";
      localStorage.setItem(
        "admin_selected_tool",
        JSON.stringify(admin_selected_blog)
      );
      setinputData({
        ...inputData,
        blog_image: "",
      });
      createBlogFn(null, contentEditableRef.current.innerHTML);
      if (!response.data.error) {
        // Handle success
      } else {
        // Handle error
      }
    } catch (error) {
      console.log(error);
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    // Starts the upload process.
    async upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            this._uploadImage(file)
              .then((response) => {
                resolve({
                  default: response.default,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    // Handles the actual upload process.
    async _uploadImage(file) {
      return uploadImage(file);
    }

    abort() {
      // Reject the promise returned from the upload() method.
    }
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }
  console.log("This page modified");
  useEffect(() => {
    if (!rawFile) {
      if (params?.id == "new") {
        setinputData({
          title: "",
        });
        setpreviewUrl(null);
      } else {
        setinputData({
          ...inputData,
          title: JSON.parse(localStorage.getItem("admin_selected_tool"))?.title,
          description: JSON.parse(localStorage.getItem("admin_selected_tool"))
            ?.description,
          slug: JSON.parse(localStorage.getItem("admin_selected_tool"))?.slug,
          api: JSON.parse(localStorage.getItem("admin_selected_tool"))?.api,
          icon: JSON.parse(localStorage.getItem("admin_selected_tool"))?.icon,
          meta_title: JSON.parse(localStorage.getItem("admin_selected_tool"))
            ?.meta_title,
          meta_description: JSON.parse(
            localStorage.getItem("admin_selected_tool")
          )?.meta_description,
          tool_type: JSON.parse(localStorage.getItem("admin_selected_tool"))
            ?.tool_type,
        });
        setFaqs(
          JSON.parse(localStorage.getItem("admin_selected_tool"))?.faqs || []
        );
        // setpreviewUrl(
        //   JSON.parse(localStorage.getItem("admin_selected_tool"))?.blog_image
        // );
      }
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    alignment: {
      options: ["left", "right"],
    },
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "imageUpload",
      "undo",
      "redo",
      "|",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
    ],
  };
  const handleContentChange = (newData) => {
    if (typingTimer) {
      clearTimeout(typingTimer);
    }

    // Set a new timer to call the save API after typingTimeout milliseconds
    const newTypingTimer = setTimeout(() => {
      createBlogFn(null, newData);
    }, typingTimeout);

    setTypingTimer(newTypingTimer);
  };
  useEffect(() => {
    if (params?.id !== "new") {
      setEditorData(
        JSON.parse(localStorage.getItem("admin_selected_tool"))?.content
      );
    }
  }, []);
  console.log(inputData, "input data");

  console.log(previewUrl, "preview url");
  return (
    <div>
      <Form
        onSubmit={(e) => {
          createBlogFn(e, contentEditableRef.current.innerHTML);
        }}
      >
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Title"
            value={inputData.title}
            onChange={(e) => {
              setinputData({
                ...inputData,
                title: e.target.value,
              });
            }}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={inputData.description}
            onChange={(e) => {
              setinputData({
                ...inputData,
                description: e.target.value,
              });
            }}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Meta Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Meta Title"
            value={inputData.meta_title}
            onChange={(e) => {
              setinputData({
                ...inputData,
                meta_title: e.target.value,
              });
            }}
            maxLength="60"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Meta Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Meta Description"
            value={inputData.meta_description}
            onChange={(e) => {
              setinputData({
                ...inputData,
                meta_description: e.target.value,
              });
            }}
            maxLength="160"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Slug</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Slug"
            value={inputData.slug}
            onChange={(e) => {
              setinputData({
                ...inputData,
                slug: e.target.value,
              });
            }}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Tool Type</Form.Label>
          <Form.Control
            as="select"
            value={inputData.tool_type}
            onChange={(e) => {
              setinputData({
                ...inputData,
                tool_type: e.target.value,
              });
            }}
            disabled
          >
            <option value="">Select Tool Type</option>
            <option value="Writing Tools">Writing Tools</option>
            <option value="SEO Tools">SEO Tools</option>
            <option value="Social Tools">Social Tools</option>
            <option value="Diagram Tools">Diagram Tools</option>
          </Form.Control>
        </Form.Group>
        {state?.is_admin && (
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Api</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="Enter Api"
              value={inputData.api}
              onChange={(e) => {
                setinputData({
                  ...inputData,
                  api: e.target.value,
                });
              }}
              required
            />
          </Form.Group>
        )}
        {/* <Form.Group className="mb-3 mt-3">
          <Form.Label>Icon</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Icon"
            value={inputData.icon}
            onChange={(e) => {
              setinputData({
                ...inputData,
                icon: e.target.value,
              });
            }}
            required
          />
        </Form.Group> */}
        {/* <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Blog Image</Form.Label>
          <Form.Control
            type="file"
            ref={imageRef}
            accept="image/*"
            onChange={(e) => {
              uploadIconFn(e.target.files[0]);
            }}
          />

          <div className="mt-3 mb-3">
            {inputData.blog_image ? (
              <div className="w-[150px] h-[150px] rounded-[50%] flex items-center justify-center relative">
                <img
                  src={inputData.blog_image}
                  alt="blog"
                  className="w-full object-cover"
                />
                <button
                  type="button"
                  className="absolute right-0 top-0 text-[#DC3545] hover:text-[#B52F3C]"
                  onClick={() => {
                    deleteImage(inputData.blog_image, "blog");
                  }}
                >
                  <svg className="icon text-[26px]">
                    <use href="#icon_delete"></use>
                  </svg>
                </button>
              </div>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>
        </Form.Group> */}
        <Button
          type="submit"
          disabled={isSourceView}
          className="logout-btn grd-btn py-2"
        >
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            "Save"
          )}
        </Button>
      </Form>
      <FaqComponent faqs={faqs} setFaqs={setFaqs} addFaq={addFaq} />
      <Button
        variant="primary"
        onClick={() => addFaq(faqs.length - 1)}
        className="mt-3 mb-3 grd-btn"
      >
        Add FAQ
      </Button>
      <div className="my-3">
        {/*<CKEditor
          editor={ClassicEditor}
          data={editorData}
          config={editorConfiguration}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
            const newData = editor.getData();
          }}
          onChange={(event, editor) => {
            const oldData = uploadedImages.current.join("");
            const newData = editor.getData();
            console.log(newData, "new Data...");

            handleImageDelete(oldData, newData);

            uploadedImages.current = Array.from(
              newData.matchAll(/<img.*?src="(.*?)"/g)
            ).map((match) => match[1]);
            handleContentChange(newData);
            setEditorData(newData);
          }}
          onDestroy={() => {
            // Optionally, you could delete images here when the editor is destroyed.
          }}
        />*/}

        <TextEditor
          blog_title={inputData.title}
          blog_image={rawFile}
          contentEditableRef={contentEditableRef}
          createBlogFn={createBlogFn}
          params={params}
          localStorageKey={"admin_selected_tool"}
          isSourceView={isSourceView}
          setIsSourceView={setIsSourceView}
        />
      </div>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </div>
  );
}

export default AiToolPage;
