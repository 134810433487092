/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { Modal } from "flowbite-react";
import { Modal } from "react-bootstrap";
import { Remarkable } from "remarkable";
import useCommonApi from "../hooks/common-api";
import interact from "interactjs";

const TextEditor = ({
  blog_title,
  blog_image,
  contentEditableRef,
  createBlogFn,
  params,
  localStorageKey,
  isSourceView,
  setIsSourceView,
}) => {
  const [showTableModal, setShowTableModal] = useState(false);
  const [tableRows, setTableRows] = useState(2);
  const [tableColumns, setTableColumns] = useState(2);

  const openTableModal = () => setShowTableModal(true);
  const closeTableModal = () => setShowTableModal(false);

  const insertTable = () => {
    applyStyleSubFn("table", { rows: tableRows, columns: tableColumns });
    closeTableModal();
  };
  const { createBlog, updateBlog } = useCommonApi();
  const [fontSize, setFontSize] = useState("16px"); // Initial font size
  const imageRef = useRef(null);
  const [tooltip, setTooltip] = useState(null);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingTimeout = 1000; // Set the timeout value in milliseconds (adjust as needed)
  const [cursorPosition, setCursorPosition] = useState(0);
  const [savedSelection, setSavedSelection] = useState(null);
  const [stringOutput, setstringOutput] = useState("");
  const [selectedFontSize, setSelectedFontSize] = useState("16px");
  const [history, setHistory] = useState([""]); // Initialize with an empty string
  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSavedSelection(range);
    }
  };
  // console.log(stringOutput, "string output");

  const restoreSelection = () => {
    if (savedSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(savedSelection);
    }
  };

  const selectedContentSettings = JSON.parse(
    localStorage.getItem("selectedContentSettings")
  );
  const documentBodyRef = useRef(null);
  const [openModal, setopenModal] = useState(undefined);
  const [imageUrl, setimageUrl] = useState("");
  const [alttext, setalttext] = useState("");
  const navigate = useNavigate();
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [displayChooseFile, setdisplayChooseFile] = useState(true);
  const [imageWH, setimageWH] = useState({
    width: "300",
    height: "300",
  });
  const [totalText, settotalText] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [startIndex, setStartIndex] = useState("");
  const [endIndex, setEndIndex] = useState("");
  const [ws, setWs] = useState(null);
  const [loader, setloader] = useState(false);
  const [reWriteWords, setreWriteWords] = useState("");
  const [rewriting, setrewriting] = useState(false);
  const [rewritingClicked, setrewritingClicked] = useState(false);
  const uploadedImages = useRef([]);
  const [editorState, setEditorState] = useState("");
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const textAreaRef = useRef(null);

  const openModalFn = () => {
    // Save the current cursor position
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSavedSelection(selection.getRangeAt(0).cloneRange());
    }
    setalttext("");
    setimageUrl("");
    setpreviewUrl(null);
    setrawFile(null);
    setdisplayChooseFile(true);

    // Open the modal
    setopenModal("dismissible");
  };
  // console.log(openModal, "open modal");
  // Example function of remarkable
  const getRemarkableText = () => {
    // return innerHTML;
    const md = new Remarkable();
    md.set({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      quotes: "“”‘’",
      xhtmlOut: true,
    });
    // Example
    // const stringOutput = `# Hello world h1! \n\n  ## H2 \n\n **bold** \n\n *italic*\n\n > This is a blockquote\n\n ![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "Logo Title Text 1")\n\n [This is a link](https://www.google.com/)\n\n* This is a list item`;
    // stringIn=stringIn.replaceAll("\n/g", "<br/>");
    const html = md.render(stringOutput || "");
    // console.log(html, "html");
    return html;
  };
  const [htmlContent, setHtmlContent] = useState("");

  // console.log(imageWH, "image wh");

  const applyStyleSubFn = async (style, value = null, image_id = null) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    if (
      selection &&
      selection.rangeCount > 0 &&
      contentEditableRef.current.contains(
        selection.getRangeAt(0).commonAncestorContainer
      )
    ) {
      const wrapSelectedText = (element) => {
        element.appendChild(document.createTextNode(selectedText));
        range.deleteContents();
        range.insertNode(element);
      };

      const createStyledElement = (tag, styles = {}, classNames = []) => {
        let element;
        if (tag == "font") {
          element = document.createElement("span");
        } else {
          element = document.createElement(tag);
        }
        let tags = ["h1", "h2", "h3", "h4", "h5", "h6", "font"];
        if (tags.includes(tag)) {
          // console.log("came in h1");
          Object.assign(element.style, { ...styles });
        } else {
          // console.log("came in other");
          Object.assign(element.style, { ...styles, fontSize: "16px" }); // Ensure base font size
        }
        element.className = classNames.join(" ");
        return element;
      };

      const applyListStyle = (listType, classNames = []) => {
        const listElement = document.createElement(listType);
        if (classNames.length > 0) {
          listElement.className = classNames.join(" ");
        }
        const listItem = document.createElement("li");
        listItem.appendChild(document.createTextNode(selectedText));
        listElement.appendChild(listItem);
        range.deleteContents();
        range.insertNode(listElement);
      };
      switch (style) {
        case "bold":
        case "italic":
        case "underline":
          document.execCommand(style, false, null);
          break;
        case "heading":
          const headingClasses = {
            h1: "text-5xl font-extrabold dark:text-white",
            h2: "text-4xl font-bold dark:text-white",
            h3: "text-3xl font-bold dark:text-white",
          };
          const heading = createStyledElement(value, {}, [
            headingClasses[value],
          ]);
          // heading.id = selectedText.toLowerCase().replace(/ /g, "_");
          wrapSelectedText(heading);
          break;
        case "fontSize":
          const fontSizeNode = createStyledElement("font", { fontSize: value });
          wrapSelectedText(fontSizeNode);
          break;
        case "unlist":
          const ulListClasses = ["list-disc", "ml-5"];
          applyListStyle("ul", ulListClasses);
          break;
        case "orlist":
          applyListStyle("ol");
          break;
        case "align":
          const parentNode = range.commonAncestorContainer.parentNode;
          if (parentNode && parentNode.nodeType === Node.ELEMENT_NODE) {
            parentNode.style.textAlign = value;
          } else {
            const alignNode = createStyledElement("div", { textAlign: value });
            wrapSelectedText(alignNode);
          }
          break;
        case "normal":
          // const normalText = document.createTextNode(selectedText);
          // const parentNodeNormal = range.commonAncestorContainer.parentNode;
          // if (parentNodeNormal && parentNodeNormal.hasAttribute("className")) {
          //   parentNodeNormal.removeAttribute("className");
          // }
          // if (parentNodeNormal && parentNodeNormal.hasAttribute("class")) {
          //   parentNodeNormal.removeAttribute("class");
          // }
          // range.deleteContents();
          // range.insertNode(normalText);
          document.execCommand("removeFormat", false, null);
          break;
        case "table":
          const { rows, columns } = value;
          const table = document.createElement("table");
          table.style.margin = "0 auto"; // Center the table
          table.style.borderCollapse = "collapse";

          for (let i = 0; i < rows; i++) {
            const tr = document.createElement("tr");
            for (let j = 0; j < columns; j++) {
              const td = document.createElement("td");
              const div = document.createElement("div");
              div.className = "td-content";
              td.style.border = "2px solid #ccc";
              td.appendChild(div);
              tr.appendChild(td);
            }
            table.appendChild(tr);
          }

          range.deleteContents();
          range.insertNode(table);
          break;
        case "image":
          const range1 =
            selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
          const imgContainer = createStyledElement(
            "div",
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            ["image-container"]
          );
          imgContainer.setAttribute("data-image-id", image_id);
          imgContainer.setAttribute("contenteditable", "false");

          const imgElement = createStyledElement("img", {
            width:
              imageWH.width?.trim() !== ""
                ? imageWH.width?.trim() + "px"
                : "300px",
            height:
              imageWH.height?.trim() !== ""
                ? imageWH.height?.trim() + "px"
                : "300px",
            objectFit: "fill",
          });
          imgElement.src = image_id;
          imgElement.alt = alttext;
          imgContainer.appendChild(imgElement);
          imgContainer.classList.add("group");

          range1.deleteContents();
          range1.insertNode(imgContainer);

          setimageUrl("");
          setrawFile(null);
          setpreviewUrl(null);
          setdisplayChooseFile(false);
          setTimeout(() => {
            setdisplayChooseFile(true);
          }, 10);
          setimageWH({
            width: "300",
            height: "300",
          });
          break;
        default:
          break;
      }
      if (style !== "bold" && style !== "italic" && style !== "underline") {
        handleContentChange({
          target: {
            innerHTML: contentEditableRef.current.innerHTML,
          },
        });
      }
    }

    contentEditableRef.current.focus();
  };

  // console.log(uploadedImages, "uploaded images");
  const applyStyle = async (style, value = null) => {
    if (style == "image" && rawFile && previewUrl) {
      await uploadIconFn(style, value)?.data;
    } else {
      applyStyleSubFn(style, value, null);
    }
    setopenModal(undefined);
  };

  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);

  const uploadIconFn = async (style, value) => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/editorimage`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        applyStyleSubFn(style, value, response.data.file_path);

        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  const deleteImage = async (dataImageId) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/delete/upload_file`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: {
          file_path: dataImageId,
        },
      });

      if (!response.data.error) {
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (typingTimer) {
        clearTimeout(typingTimer);
      }
    };
  }, [typingTimer]);

  const handleImageDelete = (newData) => {
    const newImages = Array.from(newData.matchAll(/<img.*?src="(.*?)"/g)).map(
      (match) => match[1]
    );
    // console.log(newImages, "images");

    uploadedImages?.current?.forEach((oldImage) => {
      if (!newImages.includes(oldImage)) {
        deleteImage(oldImage, "editor");
      }
    });
  };
  // console.log(undoStack, "save state");
  const handleContentChange = (event) => {
    const updatedContent = event.target.innerHTML;
    handleImageDelete(contentEditableRef.current.innerHTML);

    // Clear the previous timer
    if (typingTimer) {
      clearTimeout(typingTimer);
    }

    // Set a new timer to call the save API after typingTimeout milliseconds
    const newTypingTimer = setTimeout(() => {
      saveContentToApi(updatedContent);
    }, typingTimeout);

    setTypingTimer(newTypingTimer);
  };

  const saveContentToApi = (content) => {
    // Create a temporary element to hold the HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = contentEditableRef.current.innerHTML;

    // Get all elements with class 'hover-buttons' and remove them
    let hoverButtons = tempElement.querySelectorAll(".hover-buttons");
    hoverButtons.forEach((button) => button.parentNode.removeChild(button));

    // Get the cleaned HTML
    let cleanedHTML = tempElement.innerHTML;

    // console.log(cleanedHTML);

    // createBlogFn(null,contentEditableRef.current.innerHTML);

    // if (content && content != "") {
    //   callUpdateToFileApi(selectedContentSettings, content);
    // }
  };
  useEffect(() => {
    if (params?.id !== "new") {
      if (localStorageKey == "admin_selected_blog") {
        contentEditableRef.current.innerHTML = JSON.parse(
          localStorage.getItem("admin_selected_blog")
        )?.blog_content;
        // setstringOutput(
        //   JSON.parse(localStorage.getItem("admin_selected_blog"))?.blog_content
        // );
        setHtmlContent(
          JSON.parse(localStorage.getItem("admin_selected_blog"))?.blog_content
        );
      } else if (localStorageKey == "admin_selected_tool") {
        contentEditableRef.current.innerHTML = JSON.parse(
          localStorage.getItem("admin_selected_tool")
        )?.content;
        // setstringOutput(
        //   JSON.parse(localStorage.getItem("admin_selected_tool"))?.content
        // );
        setHtmlContent(
          JSON.parse(localStorage.getItem("admin_selected_tool"))?.content
        );
      }
    }
  }, []);
  const getFontSizeAtCursor = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const node =
        range.startContainer.nodeType === 3
          ? range.startContainer.parentNode
          : range.startContainer;
      return window.getComputedStyle(node).fontSize;
    }
    return null;
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Enter") {
      // Store the images before deleting
      uploadedImages.current = Array.from(
        contentEditableRef.current.innerHTML.matchAll(/<img.*?src="(.*?)"/g)
      ).map((match) => match[1]);
      // updateTOC();
    }
  };

  const handleInput = (e) => {
    // console.log("changing...");
    const content = contentEditableRef?.current?.innerHTML;
    // Check if the content is empty
    if (content === "" || content === "<br>") {
      // If empty, wrap it in <p></p>
      contentEditableRef.current.innerHTML = "<p><br></p>";
    }
    handleContentChange(e);
  };
  const handleSelectionChange = () => {
    if (contentEditableRef.current.contains(document.activeElement)) {
      const fontSize = getFontSizeAtCursor();
      // console.log(fontSize, "font size");
      if (fontSize) {
        setSelectedFontSize(fontSize);
      }
    }
  };

  const sourceView = () => {
    const updatedHtml = `${contentEditableRef?.current.innerHTML}`;
    setHtmlContent(updatedHtml);
    setIsSourceView(true);
  };
  const normalView = () => {
    setHtmlContent(textAreaRef?.current?.value);
    setIsSourceView(false);
  };

  useEffect(() => {
    if (isSourceView && textAreaRef && textAreaRef?.current) {
      textAreaRef.current.focus();
      textAreaRef.current.value = htmlContent;
    } else {
      contentEditableRef.current.innerHTML = htmlContent;
      // setstringOutput(htmlContent);
    }
  }, [htmlContent, isSourceView, textAreaRef]);

  useEffect(() => {
    const contentEditableElement = contentEditableRef.current;

    const handleMouseOver = (e) => {
      const imgContainer = e.target.closest(".image-container");
      if (imgContainer) {
        addHoverButtons(imgContainer);
      }
    };

    const createHoverButton = (innerHtml, positionClass, onClick) => {
      const button = document.createElement("div");
      button.className = positionClass;
      button.innerHTML = innerHtml;
      button.onclick = onClick;
      return button;
    };

    const addHoverButtons = (imgContainer) => {
      let hoverButtons = imgContainer.querySelector(".hover-buttons");
      if (!hoverButtons) {
        hoverButtons = document.createElement("div");
        hoverButtons.className = "hover-buttons";

        const closeBtn = createHoverButton("x", "close-btn", () => {
          const dataImageId = imgContainer.getAttribute("data-image-id");
          // console.log(dataImageId, "data image id");
          deleteImage(dataImageId);
          imgContainer.remove();
        });

        const topLeftBtn = createHoverButton("&#8593;", "top-left", () => {
          const newLine = document.createElement("div");
          newLine.innerHTML = "<br>";
          imgContainer.parentNode.insertBefore(newLine, imgContainer);
          const newRange = document.createRange();
          newRange.setStartBefore(imgContainer);
          newRange.setEndBefore(imgContainer);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        });

        const bottomRightBtn = createHoverButton(
          "&#8595;",
          "bottom-right",
          () => {
            const newLine = document.createElement("div");
            newLine.innerHTML = "<br>";
            imgContainer.parentNode.insertBefore(
              newLine,
              imgContainer.nextSibling
            );
            const newRange = document.createRange();
            newRange.setStartAfter(imgContainer);
            newRange.setEndAfter(imgContainer);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(newRange);
          }
        );

        const leftAlignBtn = createHoverButton("&#8592;", "left-align", () => {
          imgContainer.style.float = "left";
          imgContainer.style.marginRight = "10px";
          imgContainer.style.marginBottom = "10px";
        });

        const rightAlignBtn = createHoverButton(
          "&#8594;",
          "right-align",
          () => {
            imgContainer.style.float = "right";
            imgContainer.style.marginLeft = "10px";
            imgContainer.style.marginBottom = "10px";
          }
        );
        const centerAlignBtn = createHoverButton(
          "&#8645;",
          "center-align",
          () => {
            imgContainer.style.float = "none";
            imgContainer.style.margin = "10px auto";
            imgContainer.style.display = "block";
          }
        );
        const centerResizeBtn = createHoverButton(
          "⤡",
          "center-resize",
          () => {
            const currentWidth = imgContainer.querySelector("img").style.width;
            const currentHeight =
              imgContainer.querySelector("img").style.height;

            const newWidth = prompt(
              "Enter new width in pixels:",
              currentWidth || "300px"
            );
            const newHeight = prompt(
              "Enter new height in pixels:",
              currentHeight || "300px"
            );

            if (newWidth && newHeight) {
              imgContainer.querySelector("img").style.width = newWidth;
              imgContainer.querySelector("img").style.height = newHeight;
            }
          }
        );
        
        const altModifyBtn = createHoverButton(
          "Alt",
          "alt-modify",
          () => {
            const currentAlt = imgContainer.querySelector("img").alt;
            const newAlt = prompt(
              "Enter new alt text:",
              currentAlt || ""
            );
            if (newAlt) {
              imgContainer.querySelector("img").alt = newAlt;
            }
          }
        );

        hoverButtons.appendChild(closeBtn);
        hoverButtons.appendChild(topLeftBtn);
        hoverButtons.appendChild(bottomRightBtn);
        hoverButtons.appendChild(leftAlignBtn);
        hoverButtons.appendChild(rightAlignBtn);
        hoverButtons.appendChild(centerAlignBtn);
        hoverButtons.appendChild(centerResizeBtn);
        hoverButtons.appendChild(altModifyBtn);
        imgContainer.appendChild(hoverButtons);
      }
    };

    const attachHoverButtonListeners = () => {
      const imgContainers =
        contentEditableElement?.querySelectorAll(".image-container");
      imgContainers?.forEach((imgContainer) => {
        addHoverButtons(imgContainer);
      });
    };

    contentEditableElement?.addEventListener("mouseover", handleMouseOver);

    // Call attachHoverButtonListeners when the component mounts or innerHTML changes
    attachHoverButtonListeners();

    return () => {
      contentEditableElement?.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  useEffect(() => {
    const contentEditableElement = contentEditableRef.current;
    contentEditableElement?.addEventListener("keydown", handleKeyDown);
    contentEditableElement?.addEventListener("input", handleInput);
    contentEditableElement?.addEventListener(
      "selectionchange",
      handleSelectionChange
    );

    // Initialize the editor with a paragraph if empty
    if (contentEditableElement && contentEditableElement.innerHTML === "") {
      contentEditableElement.innerHTML = "<p><br></p>";
    }

    return () => {
      contentEditableElement?.removeEventListener("keydown", handleKeyDown);
      contentEditableElement?.removeEventListener("input", handleInput);
      contentEditableElement?.removeEventListener(
        "selectionchange",
        handleSelectionChange
      );
    };
  }, []);
  // console.log(htmlContent, "html content");

  return (
    <>
      {/* <div
        className="toc-container"
        id="toc-container"
        style={{
          maxHeight: "100px",
          overflow: "auto",
          border: "1px solid #ccc",
          marginBottom: "10px",
        }}
      >
        <strong>Table of Contents</strong>
        <div id="toc-list"></div>
      </div> */}
      <div>
        <div className="flex items-center gap-2 bg-[#F9F9F9] py-1 px-1">
          <div className="flex items-center gap-4 w-full border-r-2 border-[black] pr-[10px]">
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("bold", "bold")}
            >
              B
            </button>
            <button
              className="italic text-[22px]"
              onClick={() => applyStyle("italic", "italic")}
            >
              I
            </button>
            <button
              className="underline text-[22px]"
              onClick={() => applyStyle("underline", "underline")}
            >
              U
            </button>
            <div className="flex items-center gap-2 w-full">
              <label className="text-[22px]">Font</label>
              <select
                className="text-[16px]"
                value={selectedFontSize}
                onChange={(e) => {
                  const newSize = e.target.value;
                  setSelectedFontSize(newSize);
                  applyStyle("fontSize", newSize);
                }}
              >
                <option value="12px">12px</option>
                <option value="14px">14px</option>
                <option value="16px">16px</option>
                <option value="18px">18px</option>
                <option value="20px">20px</option>
                <option value="22px">22px</option>
                <option value="24px">24px</option>
              </select>
            </div>
          </div>
          <div className="flex items-center gap-4 w-full border-r-2 border-[black] pr-[10px]">
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h1")}
            >
              H1
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h2")}
            >
              H2
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h3")}
            >
              H3
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h4")}
            >
              H4
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h5")}
            >
              H5
            </button>
            <button
              className="font-extrabold text-[22px]"
              onClick={() => applyStyle("heading", "h6")}
            >
              H6
            </button>
            <button
              className="text-[22px]"
              onClick={() => applyStyle("normal")}
            >
              Normal
            </button>
          </div>
          <div className="flex items-center gap-7 w-full border-r-2 border-[black] pr-[10px]">
            <button onClick={() => applyStyle("unlist", "insertUnorderedList")}>
              <svg className="icon text-[34px]">
                <use href="#icon_list"></use>
              </svg>
            </button>
            <button onClick={() => applyStyle("orlist", "insertOrderedList")}>
              <svg className="icon text-[26px]">
                <use href="#icon_order-list"></use>
              </svg>
            </button>
            {/* <button className="text-[22px]" onClick={() => applyStyle("toc")}>
              Add to TOC
            </button> */}
            {/* <button className="text-[22px]" onClick={() => openTableModal()}>
              Table
            </button> */}
          </div>
          <div
            className="flex items-center gap-4 w-full"
            onClick={() => {
              contentEditableRef.current.focus();
              saveSelection();
              setTimeout(() => {
                openModalFn();
              }, 200);
            }}
          >
            <button>
              <svg className="icon text-[20px]">
                <use href="#icon_insert-img"></use>
              </svg>
            </button>
          </div>
          <div className="flex items-center gap-7 w-full ">
            {isSourceView ? (
              <button className="font-bold" onClick={normalView}>
                SOURCE VIEW
              </button>
            ) : (
              <button className="font-bold" onClick={sourceView}>
                EDITOR VIEW
              </button>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 bg-[#F9F9F9] py-1 px-1">
          <div className="flex items-center gap-7 w-full pr-[10px]">
            <button onClick={() => applyStyle("align", "left")}>Left</button>
            <button onClick={() => applyStyle("align", "center")}>
              Center
            </button>
            <button onClick={() => applyStyle("align", "right")}>Right</button>
            <button onClick={() => applyStyle("align", "justify")}>
              Justify
            </button>
          </div>
        </div>
        {isSourceView ? (
          <textarea
            ref={textAreaRef}
            contentEditable // Make the div editable
            style={{
              width: "100%",
              height: "72vh",
              border: "1px solid #ccc",
              padding: "10px",
              fontSize: "16px",
            }}
          />
        ) : (
          <>
            <div
              ref={contentEditableRef}
              contentEditable
              style={{
                border: "1px solid #ccc",
                minHeight: "100px",
                padding: "5px",
                fontSize: "16px",
                maxHeight: "72vh",
                overflow: "auto",
                outline: "none", // Remove the default focus outline
                width: "100%",
              }}
              onInput={(e) => handleContentChange(e)}
              className="text-lg text-editor"
              dangerouslySetInnerHTML={{ __html: stringOutput }}
              onFocus={() => {
                contentEditableRef.current.style.border =
                  "2px solid rgb(56, 140, 197)";
              }}
              onBlur={() => {
                contentEditableRef.current.style.border = "1px solid #ccc";
              }}
            />
          </>
        )}
      </div>
      <Modal
        root={documentBodyRef?.current}
        dismissible
        show={openModal === "dismissible"}
        size="md"
        onClose={() => setopenModal(undefined)}
        zIndex="1000"
        centered
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Insert/Edit Image</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="pb-3 pt-4">
            <label htmlFor="file">Image url</label>
            <input
              type="text"
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Image url"
              value={imageUrl}
              onChange={(e) => {
                setimageUrl(e.target.value);
                imageRef.current.value = null;
                if (rawFile) {
                  setrawFile(null);
                  setpreviewUrl(null);
                  imageRef.current.value = null;
                  setdisplayChooseFile(false);
                  setTimeout(() => {
                    setdisplayChooseFile(true);
                  }, 10);
                }
              }}
            />
          </div>
          <div className="text-center pb-3">or</div>
          <div className="pb-3">
            <label htmlFor="file">Upload From Local</label>
            <input
              type="file"
              id="uploaadfile"
              name="file"
              ref={imageRef}
              className="mt-1 w-full rounded-full bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Upload From Local"
              accept="image/*"
              onChange={(e) => {
                setimageUrl("");
                setrawFile(e.target.files[0]);
              }}
            />
          </div>

          <div className="pb-3">
            <label htmlFor="file">ALT Text</label>
            <input
              type="text"
              value={alttext}
              className="mt-1 w-full rounded-full bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Enter alt text"
              onChange={(e) => {
                setalttext(e.target.value);
              }}
            />
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="file">Width (in px)</label>
              <input
                type="text"
                className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
                placeholder="Width"
                value={imageWH?.width}
                onChange={(e) =>
                  setimageWH({
                    ...imageWH,
                    width: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="file">Height (in px)</label>
              <input
                type="text"
                className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
                placeholder="Height"
                value={imageWH?.height}
                onChange={(e) =>
                  setimageWH({
                    ...imageWH,
                    height: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="pt-4 flex justify-end">
            <button
              type="button"
              className="flex w-[130px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#ed1512] text-white  hover:from-[#9d3742] hover:to-[#1A71A7] me-6"
              onClick={() => {
                contentEditableRef.current.focus();
                setopenModal(undefined);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="flex w-[130px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => {
                if (imageRef.current) {
                  imageRef.current.value = null; // Clear the input field
                }
                setimageUrl("");
                setpreviewUrl(null);
                setrawFile(null);
                restoreSelection(); // Restore the saved selection
                contentEditableRef.current.focus();
                applyStyle("image");
              }}
              disabled={imageUrl == "" && !previewUrl}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*{showTableModal && (
        <div className="table-modal">
          <label>
            Rows:
            <input
              type="number"
              value={tableRows}
              onChange={(e) => setTableRows(e.target.value)}
            />
          </label>
          <label>
            Columns:
            <input
              type="number"
              value={tableColumns}
              onChange={(e) => setTableColumns(e.target.value)}
            />
          </label>
          <button onClick={insertTable}>Insert Table</button>
          <button onClick={closeTableModal}>Cancel</button>
        </div>
      )} */}
    </>
  );
};

export default TextEditor;
