/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useCommonApi from "../hooks/common-api";
import Loader from "../components/Loader";
import moment from "moment";
import * as XLSX from "xlsx";

function WaitList() {
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const [show, setShow] = useState(false);
  const [page, setpage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [waitList, setwaitList] = useState([]);
  const debounceTimeout = useRef(null);

  console.log(waitList);
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return "";
    }
    return moment(dateTimeString).format("D MMMM, YYYY | hh:mm A");
  };
  const [showMore, setshowMore] = useState(false);
  const getWaitList = async (page = 1) => {
    try {
      setloader(true);

      const response = await axios({
        url:
          search?.length > 0
            ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/wait_user_list?searchtxt=${search}&action_type=waitlist`
            : `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/wait_user_list?page=${page}&action_type=waitlist`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response?.data?.error) {
        let temp = [...waitList];
        let responseLength = response?.data?.waitlist_user?.length;
        if (page > 1) {
          temp = [...temp, ...response?.data?.waitlist_user];
        } else {
          temp = [...response?.data?.waitlist_user];
        }
        if (responseLength === 0) {
          setshowMore(false);
        } else {
          setshowMore(true);
        }
        setwaitList(temp);
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setloader(false);
    }
  };
  const exportToExcel = () => {
    const tableData = waitList.map((item, index) => ({
      SerialNumber: index + 1,
      Name: item?.name,
      Email: item?.email,
      EmailStatus: item?.verify_substatus,
      Phone: item?.phone,
      CompanyUrl: item?.company_url,
      City: item?.city,
      IPAddress: item?.ip_address,
      IPCountry: item?.ip_contry,
      LastUpdatedAt: formatDateTime(item?.updatedAt),
      PageURL: item?.page_url,
      Referrer: item?.referrer,
    }));
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "waitLists");

    // Export to Excel file
    XLSX.writeFile(workbook, "waitlist_users.xlsx");
  };
  const debouncedGetWaitList = () => {
    // Clear the existing timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      getWaitList();
    }, 500); // Adjust debounce delay (e.g., 500ms) as needed
  };

  useEffect(() => {
    if (search?.length === 0) {
      getWaitList(1);
    } else {
      setshowMore(false);
      setpage(1);
      setwaitList([]);
      debouncedGetWaitList();
    }
    // Cleanup timeout on unmount
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [search]); // Trigger effect when `search` changes
  // const [isDesktop, setIsDesktop] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Force accurate measurement of window width
  //     const currentWidth = Math.max(
  //       document.documentElement.clientWidth,
  //       window.innerWidth || 0
  //     );
  //     setIsDesktop(currentWidth > 768);
  //   };

  //   // Call handleResize initially to set the correct value
  //   handleResize();

  //   // Add event listeners for resize and orientation changes
  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("orientationchange", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //     window.removeEventListener("orientationchange", handleResize);
  //   };
  // }, []);

  // console.log(isDesktop, "isDesktop");

  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          {/* <Col sm={12} className="">
            <div className="d-flex align-items-center my-2">
              <h2 className="fw-700 fs-30 ">Waitlist User</h2>
              <div className="ms-auto">
                <button
                  onClick={exportToExcel}
                  className="grd-btn rounded-pill mx-2"
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </Col> */}
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Waitlist User</h2>
            <h6 className="py-1">
              {/* Total Blogs: {pageInfo?.total || blogListTotal?.length} */}
            </h6>
            <div className="d-flex py-4">
              <div>
                <input
                  type="search"
                  class="form-control"
                  id="searchinput"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
              <div className="ms-auto">
                <button
                  onClick={exportToExcel}
                  className="grd-btn rounded-pill mx-2"
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="">
            <div
              className="normal-tbl waitlist-tbl mt-0"
              style={{
                // overflow: isDesktop && "auto",
                // overflow: "auto",
                width: "100%",
              }}
            >
              <table>
                <tr className="tr-bg ">
                  <th>Serial Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Button</th>
                  <th>Email Status</th>
                  <th>Phone</th>
                  <th>Company Url</th>
                  <th>City</th>
                  <th>IP Address</th>
                  <th>IP Country</th>
                  <th>Last Updated At</th>
                  <th>Page URL</th>
                  <th>Referrer</th>
                </tr>
                {loader && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <Loader />
                    </td>
                  </tr>
                )}
                {waitList?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.button}</td>
                    <td>{item?.verify_substatus}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.company_url}</td>
                    <td>{item?.city}</td>
                    <td>{item?.ip_address}</td>
                    <td>{item?.ip_contry}</td>
                    <td>{formatDateTime(item?.updatedAt)}</td>
                    <td>
                      <a
                        href={item?.page_name}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {item?.page_url}
                      </a>{" "}
                    </td>
                    <td>{item?.referrer}</td>
                  </tr>
                ))}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Show More Button */}
      {showMore && search?.trim()?.length === 0 && (
        <Container>
          <Row>
            <Col className=" col-12 text-center pt-3 pb-5">
              <button
                type="button"
                className="grd-btn py-2 d-flex align-items-center mx-auto"
                disabled={loader}
                onClick={() => {
                  setpage(page + 1);
                  getWaitList(page + 1);
                }}
              >
                {loader ? <Loader /> : "Show More"}
              </button>
            </Col>
          </Row>
        </Container>
      )}
      {/*  Modal  */}
      <Modal size="" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Header className="border-0 " closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <h5 className="fw-600 fs-20 text-center">
            Choose allowed features for ｛Customer Name｝
          </h5>
          <div className="d-flex justify-content-center mt-3">
            <div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck1"
                />
                <label className="form-check-label" for="flexCheck1">
                  Dashboard
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck2"
                />
                <label className="form-check-label" for="flexCheck2">
                  Tracking
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck3"
                />
                <label className="form-check-label" for="flexCheck3">
                  SKU Cost Footprint
                </label>
              </div>
            </div>
          </div>
          <div className="text-center pb-3">
            <button
              typeof="button"
              className="normal-btn px-4 rounded-pill me-4"
            >
              Cancel
            </button>
            <button
              typeof="button"
              className="normal-btn px-4 rounded-pill me-4"
            >
              Grant Access
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WaitList;
