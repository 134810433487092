/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import { useEffect, useState } from "react";
import useCommonApi from "../hooks/common-api";
import useBaseMatch from "../hooks/useBaseMatch";

function LayoutOutlet() {
    let navigate = useNavigate();
    const [accessModules, setAccessModules] = useState([])
    let {getModuleAccessList} = useCommonApi()
    let isUserMatch = useBaseMatch('/users');
    let isBlogMatch = useBaseMatch('/blog');
    let isDashboardMatch = useBaseMatch('/dashboard');
    let isSubsicriberMatch = useBaseMatch('/subscribers');
    let isAffiliatesMatch = useBaseMatch('/affiliates');
    let isLeadsMatch = useBaseMatch('/leads');
    let isProfileMatch = useBaseMatch('/my-profile');
    let isMarketingFormMatch = useBaseMatch('/marketing-form');
    let isFeedbackMatch = useBaseMatch('/feedback');
    let isFreeAiToolsMatch = useBaseMatch('/free_ai_tools');
    let isMissedUserMatch = useBaseMatch('/missed-user');
    const {state} = useLocation()
    async function checkAccess(){
        if(state?.is_admin) return 
        let modules = await getModuleAccessList(setAccessModules);
        console.log(modules, "modules")
        if(modules && modules?.length > 0){
            // let access = modules.map((module) => module?.module_key);
            if( isUserMatch && !modules.includes('users')){
                navigate('/unauthorized');
            }else if( isBlogMatch && !modules.includes('blog')){
                navigate('/unauthorized');
            }else if( isDashboardMatch && !modules.includes('dashboard')){
                navigate('/unauthorized');
            }else if( isSubsicriberMatch && !modules.includes('subscribers')){
                navigate('/unauthorized');
            }else if( isAffiliatesMatch && !modules.includes('affiliates')){
                navigate('/unauthorized');
            }else if( isLeadsMatch && !modules.includes('leads')){
                navigate('/unauthorized');
            }else if( isProfileMatch){
                navigate('/unauthorized');
            }else if( isMarketingFormMatch && !modules.includes('marketing_form')){
                navigate('/unauthorized');
            }else if( isFeedbackMatch && !modules.includes('feedback')){
                navigate('/unauthorized');
            }else if( isFreeAiToolsMatch && !modules.includes('free_ai_tools')){
                console.log('navigating to unauthorized')
                navigate('/unauthorized');
            }else if( isMissedUserMatch && !modules.includes('missed-user')){
                console.log('navigating to unauthorized')
                navigate('/unauthorized');
            }
        }else{
            navigate('/unauthorized');
        }
    }

    useEffect(() => {
        checkAccess();
        if(!localStorage.getItem('admin_user_token') || !localStorage.getItem('admin_user_data')){
            navigate('/');
        }
    }, [])
    
    return(<>
        <AsideBar></AsideBar>
        <NavBar></NavBar>
        <div className="wrapper">
            <Outlet></Outlet>
        </div>
    </>)
}
export default LayoutOutlet;