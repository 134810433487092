/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import useCommonApi from "../hooks/common-api";

function RolesPage() {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false)
  const [role, setRole] = useState({
    role_name: "",
    modules: [],
  });
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRole({ ...role, [name]: value });
  };
  const handleClose = () => {setShow(false);
    setRole({
      role_name: "",
      modules: [],
    });
  };
  const handleShow = () => setShow(true);
  const [rolesList, setRolesList] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const { getRolesList} = useCommonApi()
  

  async function getModulesList() {
    try {
      setloader(true);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/module_list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response?.data?.error) {
        setModulesList(response?.data?.result);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setloader(false);
    }
  }
  async function handleSaveRole(e) {
    e.preventDefault();
    try {
      setSaving(true);
      let data = {...role};
      
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/create_edit_role`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });


      if (!response?.data?.error) {
        setopenSuccessModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
        getRolesList(setloader,setRolesList);
        handleClose();
        setTimeout(() => {
          removeModal();
        }, 3000);
      }else{
        setopenFailureModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setSaving(false);
    }
  }
  useEffect(() => {
    getRolesList(setloader,setRolesList);
    getModulesList();
  }, []);

  
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <div className="d-flex py-4">
              <h2 className="fw-700 fs-30">All Roles</h2>

              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn rounded-pill py-2 d-flex align-items-center"
                  onClick={() => handleShow()}
                >
                  Add User Role
                  <svg className="icon fs-20 ms-1">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Role Name</th>
                <th>Edit</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {rolesList?.map((item, index) => (
                <tr>
                  <td>
                    {item?.role_name}
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setRole({
                          role_id: item?.role_id,
                          role_name: item?.role_name,
                          modules: item?.role_modules
                        });
                        handleShow();
                      }}
                    >
                      <button type="button" className="arrow-btn ">
                        <svg className="icon fs-24">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      <Modal
        size=""
        contentClassName="rounded-4"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-1" closeButton>
          <Modal.Title className="fw-700 fs-30 mx-4">{role?.role_id ? 'Edit':'Add'} User Role</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSaveRole} className="mx-4 mt-4">
            <Form.Group className="mb-3" controlId="formRoleName">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="text"
                placeholder="Enter Role Name"
                required
                name="role_name"
                value={role.role_name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Label>Select Modules</Form.Label>
            <div className="form-group-container">
              {modulesList?.filter(module=> module?.status)?.map((module, index) => (
                <Form.Group
                  className="mb-3"
                  controlId={"formModules" + module?.module_key}
                >
                  <Form.Check
                    key={index + "module"}
                    type="checkbox"
                    label={module.module_name}
                    name={module.module_key}
                    checked={role?.modules?.find(m=>m.module_key === module.module_key) || false}
                    onChange={(e) => {
                      //if checked then add it into the role.modules array
                      //if unchecked then remove it from the role.modules array
                      if(e.target.checked){
                        setRole({
                          ...role,
                          modules: [...role?.modules, {module_name: module.module_name, module_key: module.module_key}]
                        })
                      }else{
                        setRole({
                          ...role,
                          modules: role?.modules?.filter(m=>m.module_key !== module.module_key)
                        })
                      }
                    }}
                  />
                </Form.Group>
              ))}
            </div>
            <button disabled={saving} type="submit" className="grd-btn rounded-pill w-100">
              {saving ? <Loader /> : "Save"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default RolesPage;
