/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import CryptoJS from "crypto-js";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import moment from "moment";
function MarketingForm() {
  const navigate = useNavigate();
  const {state} = useLocation()
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [show, setShow] = useState(false);
  const [marketingForm, setMarketingForm] = useState({
        form_name: '',
        form_short_code: '',
        file_title:'',
        file_description:'',
        file_cover_image: '',
        file_path: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const profilePicRef = useRef(null);
  const [rawFile1, setrawFile1] = useState(null);
  const [previewUrl1, setpreviewUrl1] = useState(null);
  const profilePicRef1 = useRef(null);
  const handleChangeFileFun = (e) => {
    // if (!editCancel) {
    //   return;
    // }
    let file = e.target.files[0];

    setrawFile(file);
    e.target.files = null;
  };
  const handleChangeFileFun1 = (e) => {
    // if (!editCancel) {
    //   return;
    // }
    let file = e.target.files[0];

    setrawFile1(file);
    e.target.files = null;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
      if(name === 'form_name'){
      setMarketingForm({...marketingForm,[name]:value ? value?.replaceAll(' ','-') : ''});
      }else{
      setMarketingForm({ ...marketingForm, [name]: value });}
  };
  const handleClose = () => {
    setShow(false);
    setrawFile(null);
    setpreviewUrl(null);
    setrawFile1(null);
    setpreviewUrl1(null);
    setMarketingForm({
        form_name: '',
        form_short_code: '',
        file_title:'',
        file_description:'',
        file_cover_image: '',
        file_path: '',
    });
  };
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [formlist, setformlist] = useState([]);
  const [saving, setSaving] = useState(false)
  const observer = useRef();
  const tempRef = useRef();
  // const lastBookElementRef = useCallback(
  //   (node) => {
  //     console.log("intersecting");
  //     if (loader) {
  //       return;
  //     }
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setCurrentPage((prev) => prev + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loader, hasMore]
  // );
 

  async function getformlistFn(from) {
    try {
      setloader(true);
      // if (from == "reload") {
      //   setCurrentPage(0);
      // }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/zoho/marketing/form_list`,
        method: "GET",
        // data: {
        //   page: from == "reload" ? 0 : currentPage,
        //   searchtxt: searchInput,
        // },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      // sethasMore(false);

      if (!response.data.error) {
        // const newList = response.data.result.filter((newList) => {
        //   return !formlist.some(
        //     (existingList) =>
        //       existingList?.aff_id === newList?.aff_id
        //   );
        // });
        // if (from == "reload") {
          setformlist(response.data?.form_list || []);
        // } else {
        //   setformlist([...formlist, ...newList]);
        // }
        // sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      // setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }

  const uploadIconFn = async (file) => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", file);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/marketing`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  async function handleSaveForm(e) {
    e.preventDefault();
    try {
      // console.log('marketingForm', marketingForm);
      setSaving(true);
      let profile_photo = "";
      if (rawFile) {
        let response = await uploadIconFn(rawFile);
        console.log(response);
        if (!response.error) {
          profile_photo = response.data;
        } else {
          setopenFailureModal(true);
          setsuccessFailureMessage(response.message);
          setSaving(false);
          return;
        }
      }
      let file_path = "";
      if (rawFile1) {
        let response = await uploadIconFn(rawFile1);
        console.log(response);
        if (!response.error) {
          file_path = response.data;
        } else {
          setopenFailureModal(true);
          setsuccessFailureMessage(response.message);
          setSaving(false);
          return;
        }
      }
      let data = {...marketingForm};
      if(rawFile){
        data.file_cover_image = profile_photo;
      }
      if(rawFile1){
        data.file_path = file_path;
      }
      if(!data?.file_cover_image){
        setopenFailureModal('dismissible');
        setsuccessFailureMessage('Please upload cover image');
        setSaving(false);
        return
      }else if(!data?.file_path){
        setopenFailureModal('dismissible');
        setsuccessFailureMessage('Please upload file');
        setSaving(false);
        return
      }
      // if(data?.password){
      //   const cipherText = CryptoJS.AES.encrypt(
      //     data?.password,
      //     process.env.REACT_APP_SECRET
      //   );
      //   data.password = cipherText.toString();
      // }else{
      //   delete data.password;
      // }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/zoho/marketing/form`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });


      if (!response?.data?.error) {
        setopenSuccessModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
        getformlistFn("reload");
        handleClose();
        setTimeout(() => {
          removeModal();
        }, 3000);
      }else{
        setopenFailureModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setSaving(false);
    }
  }
  useEffect(() => {
    // if (currentPage == 0) {
    //   getformlistFn("reload");
    // } else {
      getformlistFn();
    // }
  }, [
    // currentPage
  ]);

  // useEffect(() => {
  //   if (!searchInput.length) {
  //     getformlistFn("reload");
  //   }
  // }, [searchInput]);
  useEffect(() => {
    if (!rawFile) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    if (!rawFile1) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile1);
    setpreviewUrl1(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile1]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">All Marketing Forms</h2>
            <div className="d-flex py-4 ">
              {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getaffiliatelistFn("reload");
                }}
                className="d-flex "
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form> */}
              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn rounded-pill py-2 d-flex align-items-center"
                  onClick={() => handleShow()}
                >
                  Add Form
                  <svg className="icon fs-20 ms-1">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Form Id</th>
                <th>Form Name</th>
                <th>Short Code</th>
                <th>Cover Image</th>
                <th>File Path</th>
                <th>Created At</th>
                <th>Edit</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {formlist?.map((item, index) => (
                <tr
                  // ref={
                  //   formlist?.length == index + 1 ? lastBookElementRef : tempRef
                  // }
                  key={item?.form_name + index}
                >
                  <td>{item?.zoho_form_id}</td>
                  <td>
                    {item?.form_name}
                  </td>
                  <td>{`{{${item?.form_short_code}}}`}</td>
                  <td>
                    <img src={item?.file_cover_image} alt={item?.file_cover_image} className="h-[100px] w-[100px] rounded-full"/>
                  </td>
                  <td>
                    <a href={item?.file_path} target="_blank" rel="noreferrer">
                      {item?.file_path.split('/').pop()}
                    </a>
                    {/* on click of url download the file */}
                    {/* <a
                      href={item?.file_path}
                      rel="noreferrer"
                      onClick={async (e) => {
                        e.preventDefault();
                        try {
                          const response = await fetch(item?.file_path);
                          const blob = await response.blob();
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement('a');
                          link.href = url;
                          link.download = item?.file_path.split('/').pop();
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                        } catch (error) {
                          console.error('Error downloading the file', error);
                        }
                      }}
                    >
                      {item?.file_path}
                    </a> */}
                  </td>
                  <td>{item?.createdAt && moment(item?.createdAt).format('DD MMM YYYY')}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setMarketingForm({
                          form_name: item?.form_name,
                          form_short_code: item?.form_short_code,
                          file_title: item?.file_title,
                          file_description: item?.file_description,
                          file_cover_image: item?.file_cover_image,
                          file_path: item?.file_path,
                          zoho_form_id: item?.zoho_form_id,
                        })
                        let fileCoverImage = item?.file_cover_image;
                        if (fileCoverImage) {
                          setpreviewUrl(fileCoverImage);
                        } else {
                          setpreviewUrl(null);
                        }
                        if(item?.file_path){
                          setpreviewUrl1(item?.file_path);
                        }else{
                          setpreviewUrl1(null);
                        }
                        handleShow();
                      }}
                    >
                      <button type="button" className="arrow-btn ">
                        <svg className="icon fs-24">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      <Modal
        size="lg"
        contentClassName="rounded-4"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-1" closeButton>
          <Modal.Title className="fw-700 fs-30 mx-4">{marketingForm?.zoho_form_id ? 'Edit' : 'Add'} Marketing Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSaveForm} className="mx-4 mt-4">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Zoho Form Name</Form.Label>
                  <Form.Control
                    className="rounded-pill1"
                    type="text"
                    placeholder="Enter Zoho Form Name"
                    required
                    name="form_name"
                    value={marketingForm.form_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formShortCode">
                  <Form.Label>Form Short Code</Form.Label>
                  <Form.Control
                    className="rounded-pill1"
                    type="text"
                    placeholder="Enter Form Short Code"
                    required
                    readOnly={marketingForm?.zoho_form_id}
                    disabled={marketingForm?.zoho_form_id}
                    name="form_short_code"
                    value={marketingForm?.form_short_code}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>File Title</Form.Label>
                  <Form.Control
                    className="rounded-pill1"
                    type="text"
                    placeholder="Enter File Title"
                    required
                    name="file_title"
                    value={marketingForm.file_title}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formShortCode">
                  <Form.Label>File Description</Form.Label>
                  <Form.Control
                    className="rounded-pill1"
                    as="textarea"
                    placeholder="Enter File Description"
                    required
                    name="file_description"
                    value={marketingForm?.file_description}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            
            
            <Row>
              <Col>
                {/* <Form.Group className="mb-3" controlId="formCoverImage">
                  <Form.Control
                  className="rounded-pill"
                  type="text"
                  placeholder="Country"
                  required
                  name="country"
                  value={marketingForm?.country}
                  onChange={handleInputChange}
                  />
                  </Form.Group> */}
                <Form.Label>Cover Image</Form.Label>

                <div
                  id="file-upload-form"
                  className="uploader  w-[120px] h-[120px] "
                  style={{ width: "120px", height: "120px" }}
                >
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    // required
                    // disabled={!editCancel}
                    onChange={(e) => {
                      // if (!editCancel) {
                      //   return;
                      // }
                      handleChangeFileFun(e);
                    }}
                  />

                  <label
                    className="flex justify-center items-center cursor-pointer"
                    htmlFor="file-upload"
                    id="file-drag"
                    style={{
                      width: "120px",
                      height: "120px",
                      background: previewUrl && "none",
                    }}
                    // onClick={() => {
                    //   if (!editCancel) {
                    //     return;
                    //   }
                    // }}
                    ref={profilePicRef}
                  >
                    <div id="start">
                      {previewUrl ? (
                        <div className="relative">
                          <div className="rounded-[50%] w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                            <img
                              src={previewUrl}
                              className="w-full object-cover"
                              alt="..."
                            />
                          </div>
                          {/* {editCancel && ( */}
                            <button
                              type="button"
                              className="absolute w-[25px] h-[25px] bg-[#5DE56A] right-[5px] bottom-[5px] rounded-[50%] text-[white] hover:text-[red]"
                              onClick={() => profilePicRef?.current?.click()}
                            >
                              <svg className="icon">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          {/* )} */}
                        </div>
                      ) : (
                        <>
                          <svg className="icon text-[24px]">
                            <use href="#icon_upload"></use>
                          </svg>
                          <div id="notimage" className="text-sm">
                            Please select an image
                          </div>
                        </>
                      )}
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                    </div>
                  </label>
                </div>
              </Col>
              <Col>
                {/* <Form.Group className="mb-3" controlId="formCoverImage">
                  <Form.Control
                  className="rounded-pill"
                  type="text"
                  placeholder="Country"
                  required
                  name="country"
                  value={marketingForm?.country}
                  onChange={handleInputChange}
                  />
                  </Form.Group> */}
                <Form.Label>File Path</Form.Label>

                <div
                  id="file-upload-form"
                  className="uploader  w-[120px] h-[120px] "
                  style={{ width: "120px", height: "120px" }}
                >
                  <input
                    id="file-upload1"
                    type="file"
                    name="fileUpload1"
                    // accept pdf
                    accept=".pdf"
                    // required
                    // disabled={!editCancel}
                    onChange={(e) => {
                      // if (!editCancel) {
                      //   return;
                      // }
                      handleChangeFileFun1(e);
                    }}
                  />

                  <label
                    className="flex justify-center items-center cursor-pointer"
                    htmlFor="file-upload1"
                    id="file-drag"
                    style={{
                      width: "120px",
                      height: "120px",
                      background: previewUrl1 && "none",
                    }}
                    // onClick={() => {
                    //   if (!editCancel) {
                    //     return;
                    //   }
                    // }}
                    ref={profilePicRef1}
                  >
                    <div id="start">
                      {previewUrl1 ? (
                        <div className="relative">
                          <div className="rounded-[50%] w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                            <img
                              src={previewUrl1 ? '/images/pdf.svg' : previewUrl1}
                              className="w-full object-cover"
                              alt="..."
                            />
                          </div>
                          {/* {editCancel && ( */}
                            <button
                              type="button"
                              className="absolute w-[25px] h-[25px] bg-[#5DE56A] right-[5px] bottom-[5px] rounded-[50%] text-[white] hover:text-[red]"
                              onClick={() => profilePicRef1?.current?.click()}
                            >
                              <svg className="icon">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          {/* )} */}
                        </div>
                      ) : (
                        <>
                          <svg className="icon text-[24px]">
                            <use href="#icon_upload"></use>
                          </svg>
                          <div id="notimage" className="text-sm">
                            Please select a file
                          </div>
                        </>
                      )}
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                    </div>
                  </label>
                </div>
              </Col>
            </Row>
            <button disabled={saving} type="submit" className="grd-btn mt-2 rounded-pill w-100">
              {
                saving ? <Loader /> : 'Save'
              }
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default MarketingForm;
