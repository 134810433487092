/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import moment from "moment";

function FreeAiTools() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  // const [show, setShow] = useState(false);

  // const handleClose = () => {
  //   setShow(false)
  //   setSelectedUser()
  // };
  // const handleShow = (user) => {
  //   if(user?.referral_code){
  //   setShow(true)
  //   setSelectedUser(user)
  // }
  // };
  // const [selectedUser, setSelectedUser] = useState()
  // const [currentPage, setCurrentPage] = useState(0);
  // const [hasMore, sethasMore] = useState(false);
  const [toolslist, settoolslist] = useState([]);
  const [toolTypes, settoolTypes] = useState([]);

  // const observer = useRef();
  // const tempRef = useRef();
  // const lastBookElementRef = useCallback(
  //   (node) => {
  //     console.log("intersecting");
  //     if (loader) {
  //       return;
  //     }
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setCurrentPage((prev) => prev + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loader, hasMore]
  // );
  // console.log(leadslist);

  async function gettoolslistFn(from) {
    try {
      setloader(true);
      if (from == "reload") {
        // setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/free_tools`,
        method: "GET",
        // data: {
        //   // page: from == "reload" ? 0 : currentPage,
        //   searchtxt: searchInput ? searchInput : null,

        // },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      // sethasMore(false);

      if (!response.data.error) {
        // const newList = response.data.result.filter((newList) => {
        //   return !leadslist.some((existingList) => existingList?.user_id === newList?.user_id);
        // });
        // if (from == "reload") {
        let visonTools = response.data?.free_tools;
        let tool_types = [];
        let tool_types_filtered = [];
        visonTools.forEach((tool) => {
          if (!tool_types.includes(tool?.tool_type)) {
            tool_types.push(tool?.tool_type);
            tool_types_filtered.push({
              tool_type: tool?.tool_type,
              tool_type_icon: tool?.tool_type_icon,
            });
          }
        });
        settoolslist(response.data?.free_tools);
        settoolTypes(tool_types_filtered);
        // } else {
        //   setleadslist([...leadslist, ...newList]);
        // }
        // sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      // setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }
  useEffect(() => {
    gettoolslistFn();
  }, []);

  // useEffect(() => {
  //   if (!searchInput.length) {
  //     gettoolslistFn("reload");
  //   }
  // }, [searchInput]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Free Ai Tools</h2>
            <div className="d-flex space-x-4">
              {/*<form
                onSubmit={(e) => {
                  e.preventDefault();
                  gettoolslistFn("reload");
                }}
                className="d-flex mt-2"
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form>*/}

              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn py-2 d-flex align-items-center"
                  onClick={() => {
                    localStorage.setItem(
                      "admin_selected_tool",
                      JSON.stringify({})
                    );
                    navigate("/free_ai_tools/new", { state });
                  }}
                >
                  <svg className="icon fs-20 me-1">
                    <use href="#icon_plus"></use>
                  </svg>
                  Create New Tool
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {/* <Col sm={12} className="normal-tbl"> */}
          {/* <table>
              <tr className="tr-bg">
                <th>Lead Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>State</th>
                <th>Trial</th>
                <th>Remark</th>
                <th>Page URL</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {toolslist?.map((item, index) => (
                <tr
                  // ref={
                  //   leadslist?.length == index + 1 ? lastBookElementRef : tempRef
                  // }
                  key={item?.email + item?.id}
                >
                  <td>{item?.id}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.city}</td>
                  <td>{item?.state}</td>
                  <td>{item?.trial ? "Yes" : "No"}</td>
                  <td>{item?.remark}</td>
                  <td>
                    <a href={item?.page_url} target="_blank">
                      {item?.page_url}
                    </a>{" "}
                  </td>
                </tr>
              ))}
            </table> */}
          {loader ? (
            <Loader />
          ) : (
            <Container className="py-5">
              {toolTypes?.map((tool_type, tool_type_index) => (
                <section
                  className=""
                  style={{
                    backgroundColor: tool_type_index % 2 == 0 && "#F6FCFF",
                  }}
                >
                  <Container className="py-5">
                    <Row className="pb-5">
                      <Col className="col-12 d-flex align-items-center justify-content-center">
                        <div
                          className="me-2"
                          style={{
                            width: "50px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tool_type?.tool_type_icon || "",
                            }}
                          />
                        </div>
                        <h1 className="ff-reemkufi  display-4 fw-600 text-grd text-center">
                          {tool_type?.tool_type}
                        </h1>
                      </Col>
                    </Row>
                    <Row className="row row-cols-1 row-cols-md-3 g-5 tools-card d-flex justify-content-center">
                      {toolslist?.filter(tool=>tool?.tool_type===tool_type?.tool_type)?.map((item, index) => (
                        <Col className="card-box">
                          <div
                            onClick={() => {
                              localStorage.setItem(
                                "admin_selected_tool",
                                JSON.stringify(item)
                              );
                              navigate(`/free_ai_tools/${item?.id}`, { state });
                            }}
                            className="card h-100 "
                          >
                            <div className="card-body py-4 text-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.icon || "",
                                }}
                                className="icon"
                              />
                              {/* <svg >
                        <use href={item?.icon ? "#" + item?.icon : "#icon_free_ai_tools"}></use>
                      </svg> */}
                              <h5 className="card-title py-3">
                                {item?.title} <br />
                              </h5>
                              <p className="card-text">{item?.description}</p>
                              {/* <a href="#" className="stretched-link"></a> */}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </section>
              ))}
            </Container>
          )}
          {/* </Col> */}
        </Row>
      </Container>
      {/* Modal  */}
      {/* <Modal size="lg" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Header className="border-0 " closeButton>
        <h5 className="fw-600 fs-20 text-center">
          Reffered Users </h5></Modal.Header>
         
        <Modal.Body className="pt-0">
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Customer Id</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
              {selectedUser?.referral_user?.map((item, index) => (
                  <tr
                  key={item?.user?.email}
                  >
                    <td>{item?.user?.user_id}</td>
                    <td>{item?.user?.first_name} {item?.user?.last_name}</td>
                    <td>{item?.user?.email}</td>
                  </tr>
                ))}
            </table>
            {selectedUser?.referral_user?.length == 0 && <div className="text-center">No Reffered Users</div>}
          </Col>
        </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default FreeAiTools;
